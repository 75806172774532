<template>
  <form class="change-nickname" @submit.prevent="handleSubmit">
    <div class="title-wrapper">
      <h2 class="title" data-t="change-nickname-form-title-0h4g">
        {{ t('mobileProfile.changeNickname') }}
      </h2>
      <NuxtI18nLink class="link" replace :to="{ query: undefined }">
        <StIcon name="cross-large" :size="20" />
      </NuxtI18nLink>
    </div>

    <StInput
      v-model="nickname"
      :label="t('mobileProfile.createNickname')"
      class="nickname-input"
      size="m"
      :error-message="errorMessage"
      :error="!!errorMessage"
      :placeholder="t('nicknameChange.nicknamePlaceholder')"
      data-t="nickname-input"
    />
    <StButton
      submit
      :label="t('nicknameChange.save')"
      class="submit-button"
      :loading="isLoading"
      size="l"
      data-t="submit-change-button-c1o9"
    />
  </form>
</template>

<script setup lang="ts">
import { useChangeNicknameForm } from './useChangeNicknameForm'

const { t } = useI18n()
const { nickname, handleSubmit, errorMessage, isLoading } =
  useChangeNicknameForm()
</script>

<style scoped>
.change-nickname {
  padding: var(--spacing-400) var(--spacing-200) var(--spacing-200)
    var(--spacing-200);
}

.title-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--spacing-150);
}

.title {
  margin: 0;
  font: var(--mobile-title-2-semibold);
}

.submit-button {
  width: 100%;
  margin-top: var(--spacing-200);
}

.link {
  color: inherit;
}
</style>
